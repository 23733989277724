<template>
<div>
    <div class="list_bg" :style="{ backgroundImage: `url(${image})`, backgroundSize: 'cover'}">
    <div class="listUl">
        <div class="list_item" v-for="item in list" :key="item.id" @click="bindClick(item)">
            <div class="list_item_cover">
                <img :src="item.bgImage" alt="">
                <img v-if="item.type == 2" class="list_item_icon" src="../../assets/hot.png" alt="">
                <img v-if="item.type == 3" class="list_item_icon" src="../../assets/comment.png" alt="">
                <img v-if="item.type == 1" class="list_item_icon" src="../../assets/point.png" alt="">
            </div>
            <div class="list_item_info">
                <div class="list_item_info_title"><span v-if="item.isTop == 1">【置顶】</span>{{item.title}}</div>
                <div class="list_item_info_description">{{item.abstractZy}}</div>
                <div class="list_item_info_remark">
                    <div >{{item.author}}</div>
                    <div>{{item.createTime}}</div>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="loading" style="text-align:center;margin:10px 0;color:#fff;">加载中</div>
    <div v-if="noMore" style="text-align:center;margin:10px 0;color:#fff;">没有更多了</div>
  </div>
</div>
  
</template>

<script>
import { defArticleList } from '@/api/request'
import wxapi from '@/api/wxapi'
export default {
    components: {},
    data() {
        return {
            image:require('@/assets/bg-red.png'),
            list:[],
            pageNum:1,
            noMore:false,
            loading:false
        };
    },
    created() {
        this.getWebList(this.pageNum);
    },
    mounted() {
        // 微信分享
        wxapi.wxRegister(this.wxRegCallback)
        //监听如果页面发生滚动时
        this.$nextTick(() => {
            window.addEventListener('scroll',this.handleScroll);
        })
    },
     // 离开这个页面销毁滚动条事件，不然会给每一个页面都触发
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        async getWebList(pageNum) {
            this.loading = false;
            let formData = new FormData();
            formData.append('pageNum', pageNum);
            formData.append('pageSize', 8);
            formData.append('label', this.$route.query.label);
            let { rows, total } = await defArticleList(formData)
            this.totalnum = Math.ceil(total/8)
            this.list = this.pageNum == 1 ? rows : this.list.concat(rows)
            this.list.forEach(el => el.createTime = el.createTime.substring(0,10))
        },
        bindClick(item){
            localStorage.setItem('detail',JSON.stringify(item))
            this.$router.push('detail?id='+item.id)
        },
        //页面滑到底部需要调用的方法
        handleScroll(){ 
            // 设备/屏幕高度
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
            // 滚动区域
            let scrollObj = document.getElementsByClassName('listUl')[0]
            // 滚动区域到头部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 滚动条的总高度
            let scrollHeight = scrollObj.scrollHeight
            // 滚动条到底部的条件
            if (scrollTop + clientHeight >= scrollHeight) {
                // 滚动区域到头部的距离 + 屏幕高度 = 可滚动的总高度
                if(!this.loading){
                    if(this.pageNum < this.totalnum){
                        this.loading = true;
                        this.pageNum += 1
                        setTimeout(() => {
                            this.getWebList(this.pageNum)
                            this.loading = false;
                        }, 2000);
                    }else{
                        this.noMore = true
                        return;
                    }
                }
            }
        },
        wxRegCallback () {
            // 用于微信JS-SDK回调
            this.wxShareTimeline()
            this.wxShareAppMessage()
        },
        wxShareTimeline () {
        // 微信自定义分享到朋友圈
            let option = {
                title: '声动课堂', // 分享标题
                link: window.location.href, // 分享链接，根据自身项目决定是否需要split
                imgUrl: 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标, 需要绝对路径
                success: () => {
                    // alert('分享成功')
                }
            }
            // 将配置注入通用方法
            wxapi.shareTimeline(option)
        },
        wxShareAppMessage () {
        // 微信自定义分享给朋友
            let option = {
                type:1,
                title: '声动课堂', // 分享标题
                desc: '学史明理 学史增信 学史崇德 学史力行', // 分享描述
                link: window.location.href, // 分享链接，根据自身项目决定是否需要split
                imgUrl: 'https://wcyc.nd-cohen.com/profile/upload/common/share.jpg', // 分享图标, 需要绝对路径
                success: () => {
                    // alert('分享成功')
                }
            }
            // 将配置注入通用方法
            wxapi.shareAppMessage(option)
        },
    },
    watch: {}
}
</script>

<style scoped>
.list_bg{
    background-color: red;
    padding: 10px;
    font-size: 14px;
    min-height: 100vh;
}
.list_item{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
}
.list_item_cover{
    position: relative;
    width: 35%;
}
.list_item_cover img{
    width: 90%;
    height: 116px;
}
.list_item_cover .list_item_icon{
    position: absolute;
    width: 40px;
    height: 18px;
    left: 3px;
    top: 3px;
}
.list_item_info{
    width: 65%;
}
.list_item_info_title{
    font-weight: bolder;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #333333;
}
.list_item_info_description{
    height: 42px;
    color: #6E6E6E;
    margin: 10px 0;
    text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
}
.list_item_info_remark{
    border-top: 1px solid #EEEEEE;
    padding-top: 10px;
    color: #FF6600;
    display: flex;
    font-size: 12px;
}
.list_item_info_remark div:nth-child(1){
    width: 65%;
}
.list_item_info_remark div:nth-child(2){
    width: 35%;
    text-align: right;
}
</style>
